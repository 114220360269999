<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <template #header>
        <subscription-list-filter
          :filter="filter"
          @search="$emit('filter-change', { search: $event })"
          @status-change="$emit('filter-change', $event)"
        ></subscription-list-filter>
      </template>
      <table-spinner :loading="loading"></table-spinner>
      <subscription-list-table
        v-if="!loading"
        :data="data"
        @cancel-click="$emit('cancel-click', $event)"
        @onSort="$emit('onSort', { ...$event })"
        :aft-field="aftField"
        :prev-field="prevField"
        :filter="filter"
      />
      <template #footer>
        <pagination
          :meta-data="meta"
          @change-page="$emit('change-page', $event)"
          @change-per-page="$emit('change-per-page', $event)"
        />
      </template>
    </b-card>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import SubscriptionListTable from "@/layouts/components/subscription/SubscriptionListTable.vue";
import SubscriptionListFilter from "@/layouts/components/subscription/SubscriptionListFilter.vue";
import Pagination from "@/layouts/components/XmlTvPagination.vue";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
export default {
  components: {
    BCard,
    Pagination,
    SubscriptionListFilter,
    TableSpinner,
    SubscriptionListTable,
  },
  props: {
    loading: Boolean,
    filter: Object,
    data: Array,
    meta: Object,
    aftField: {
      type: Array,
      required: false,
    },
    prevField: {
      type: Array,
      required: false,
    },
  },
};
</script>
<style></style>
