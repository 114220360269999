<template>
  <b-table
    id="subscription-list"
    class="position-relative"
    :items="data"
    responsive
    primary-key="id"
    :fields="fieldData"
    show-empty
    empty-text="No matching records found"
  >
    <template #head()="fieldData">
      <table-header-labels
        :fields="fieldData"
        :filter="filter"
        v-if="filter"
        @onSort="onSort({ ...$event })"
      ></table-header-labels>
    </template>
    <template #cell(email)="data">
      <div vertical-align="center">
        <b-link
          v-if="data.item.user"
          :to="{ name: 'user', params: { id: data.item.user.id } }"
          class="font-weight-bold d-block text-nowrap m-1"
        >
          {{ data.item.user.email }}
        </b-link>
        <template v-else><div class="m-1">Deleted user</div></template>
      </div>
    </template>
    <template #cell(subscription_id)="data">
      <div vertical-align="center">
        <b-link
          :to="{
            path: getSubscriptionLink(data.item.id),
          }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.subscription_id }}
        </b-link>
      </div>
    </template>
    <template #cell(begin_at)="data">
      <div vertical-align="center">
        {{ $dateHelpers.formatDate(data.item.begin_at) }} -
        {{ $dateHelpers.formatDate(data.item.finish_at) }}
      </div>
    </template>
    <template #cell(status)="data">
      <div vertical-align="center" :class="changeColor(data.item.status)">
        {{ data.item.status }}
      </div>
    </template>
    <template #cell(services)="data">
      <div>
        <span
          v-for="(service, index) in data.item.services"
          :key="service.quantity"
          :id="'tooltip-target' + data.item.subscription_id"
        >
          {{ service.name }}
          {{ data.item.services.length - 1 !== index ? ", " : "" }}
          <b-tooltip
            :target="'tooltip-target' + data.item.subscription_id"
            triggers="hover"
            variant="info"
          >
            Quantity: {{ service.quantity }}
          </b-tooltip>
        </span>
      </div>
    </template>
    <template #cell(actions)="data">
      <b-button
        v-if="data.item.status === 'ACTIVE'"
        variant="outline-primary"
        @click="$emit('cancel-click', data.item)"
      >
        Cancel
      </b-button>
    </template>

    <slot name="aftField" />
  </b-table>
</template>

<script>
import { BLink, BTable, BTooltip, BButton } from "bootstrap-vue";
import { changeTextColor } from "@/services/utils/util";
import TableHeaderLabels from "@/layouts/components/TableHeaderLabels.vue";
export default {
  components: {
    BLink,
    BTable,
    BTooltip,
    BButton,
    TableHeaderLabels,
  },
  props: {
    data: {
      type: Array,
    },
    getSubscriptionLink: {
      type: Function,
      required: false,
      default: (id) => `/subscription-list/${id}`,
    },
    prevField: {
      type: Array,
      required: false,
      default: () => [],
    },
    aftField: {
      type: Array,
      required: false,
      default: () => [],
    },
    filter: {
      type: Object,
    },
  },
  computed: {
    fieldData() {
      return [...this.prevField, ...this.fields, ...this.aftField];
    },
  },
  methods: {
    changeColor(item) {
      return changeTextColor(item);
    },
    onSort(event) {
      this.$emit("onSort", {
        sort_by: event.sort_by,
        order_by: event.order_by,
      });
    },
  },
  data() {
    return {
      fields: [
        { key: "subscription_id", label: "ID" },
        { key: "begin_at", label: "Duration" },
        { key: "status", label: "Status" },
        { key: "services", label: "Services", sortable: false },
        "",
      ],
    };
  },
};
</script>

<style></style>
