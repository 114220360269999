<template>
  <div class="d-flex flex-wrap justify-content-between w-100">
    <status-selector
      class="mt-auto col-sm-12 col-md-4 col-lg-2"
      :all-status="['ACTIVE', 'INACTIVE', 'PENDING', 'CREATED', 'CANCELLED']"
      @change="$emit('status-change', $event)"
    />
    <search-input @search="$emit('search', $event)"></search-input>
  </div>
</template>

<script>
import StatusSelector from "@/layouts/components/form/StatusSelector";
import SearchInput from "@/layouts/components/form/SearchInput.vue";
export default {
  components: {
    StatusSelector,
    SearchInput,
  },
  props: {
    filter: {
      type: Object,
    },
  },
};
</script>

<style></style>
