<template>
  <subscription-list
    :meta="meta"
    :data="data"
    :loading="loading"
    :filter="filter"
    :aft-field="aftField"
    :prev-field="prevField"
    @change-page="changePage({ page: $event })"
    @change-per-page="changePerPage({ per_page: $event })"
    @filter-change="setFilter($event)"
    @cancel-click="onCancelSubscription($event)"
    @onSort="changePage($event)"
  />
</template>
<script>
import SubscriptionList from "@/layouts/components/subscription/SubscriptionList";
import debounce from "lodash.debounce";
import { mapActions } from "vuex";
export default {
  components: {
    SubscriptionList,
  },
  data() {
    return {
      prevField: [{ key: "email", label: "Email" }],
      aftField: [{ key: "actions", label: "" }],
    };
  },
  mounted() {
    this.onRefreshPage();
  },
  computed: {
    loading() {
      return this.$store.getters["adminSubscriptionList/isLoading"];
    },
    meta() {
      return this.$store.getters[
        "adminSubscriptionList/getSubscriptionListMeta"
      ];
    },
    data() {
      return this.$store.getters[
        "adminSubscriptionList/getSubscriptionListData"
      ];
    },
    filter() {
      return {
        ...this.$route.query,
        status: this.$route.query?.status ?? undefined,
        page: this.$route.query?.page ?? 1,
        per_page: this.$route.query?.per_page ?? 10,
        sort_by: this.$route.query?.sort_by ?? "email",
        order_by: this.$route.query?.order_by ?? "asc",
      };
    },
  },
  methods: {
    ...mapActions({
      getSubscriptions: "adminSubscriptionList/getSubscriptions",
    }),
    onCancelSubscription(subscription) {
      alert(
        `Cancel subscription not implemented yet:  ${subscription?.subscription_id}`
      );
    },
    setFilter(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });

      if (filter.search !== undefined) {
        if (filter.search?.length === 0) {
          this.$router.replace({
            query: { ...this.filter, search: undefined },
          });
        }
      }

      this.onRefreshPage();
    },
    changePage(filter = {}) {
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    changePerPage(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    onRefreshPage: debounce(async function () {
      try {
        await this.getSubscriptions(this.filter);
      } catch (e) {
        console.error(e);
      }
    }, 300),
  },
};
</script>
<style></style>
